<template>
    <div>
        <el-card style="max-width: 100%">
            <div style="display: flex;gap: 20px;align-items: flex-start;flex-wrap: wrap;">
                <el-form :model="form" inline>
                    <el-form-item label="商户：">
                        <el-select v-model="form.merchant_id" placeholder="请选择商户" filterable clearable allow-create>
                            <el-option v-for="option in merchantList" :key="option.id"
                                :label="option.no + '-' + option.merchant_alias" :value="option.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间：">
                        <el-date-picker v-model="form.date" value-format="YYYY-MM-DD" type="daterange" align="right"
                            unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="变动类型：">
                        <el-select v-model="form.action" placeholder="可以筛选充值记录" style="width:200px;">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="余额增加" value="1"></el-option>
                            <el-option label="余额扣除" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" ">
                        <el-button type="primary" @click="hasSearch" plain>查询</el-button>
                        <el-button @click="resetSearch">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
    <div style="margin-top: 20px;">
        <el-card>
            <div class="table-main">
                <el-table :data="tableData" v-loading="isloading" :border="true" stripe style="width: 100%">
                    <el-table-column label="商户编号" prop="merchant.no" width="120" />
                    <el-table-column label="商户名" prop="merchant.merchant_alias" show-overflow-tooltip min-width="200" />
                    <el-table-column label="充值订单id" prop="recharge_order_id" show-overflow-tooltip min-width="160" />
                    <el-table-column label="特定通道服务费-扣费费率百分比" prop="api_fee_rate" min-width="230" />
                    <el-table-column label="备注" prop="remark" show-overflow-tooltip min-width="200" />
                    <el-table-column label="变动" prop="status" min-width="200">
                        <template #default="{ row }">
                            <el-tag :type="row.action === 2 ? 'success' : 'danger'">
                                {{ row.action === 2 ? `-${row.change_amount} 元` : `+${row.change_amount} 元` }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="余额" prop="left_amount" min-width="160" show-overflow-tooltip>
                        <template #default="{ row }">
                            {{ row.left_amount + ' 元' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="created_at" min-width="180" show-overflow-tooltip>
                        <template #default="{ row }">
                            {{ convertToChinaTimezone(row.created_at) }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination" style="margin-top: 10px;">
                    <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                        @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                        :hide-on-single-page="false">
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue';

const { proxy } = getCurrentInstance();

const form = ref({
    merchant_id: '',
    action: '',
    date: ''
});

const tableData = ref([]);
let isloading = ref(false);
let pageinfo = ref({
    perPage: 0, // 每页多少条
    total: 0, // 共计多少
    totalPages: 0, // 共计多少页
    currentPage: 1, // 当前页数
});

const pageChange = (page) => {
    hasSearch(page);
};

const hasSearch = (page) => {
    isloading.value = true;
    let info = { ...form.value, page: page };
    queryWallet(info);
};

const handleSearch = () => {
    isloading.value = true;
    pageinfo.value.currentPage = 1; // Reset to the first page
    let info = { ...form.value, page: 1 };
    queryWallet(info);
};
const resetSearch = () => {
    form.value = {};
    handleSearch();
};

// 查询商户列表
const merchantList = ref([])
const queryMerchantList = async (data) => {
    proxy.$api.dict.getMerchantDict(data).then(res => {
        if (res.status == 200) {
            merchantList.value = res.data.data
        }
    })
}

// 流量钱包余额变动记录
const queryWallet = async (data) => {

    console.log(data, 'data');

    const response = await proxy.$api.merchants.merchantWallet(data);
    tableData.value = response.data.data;
    pageinfo.value = {
        perPage: response.data.pagination.perPage,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.totalPages,
        currentPage: response.data.pagination.currentPage
    };
    isloading.value = false;
};

const convertToChinaTimezone = (utcDate) => {
    const date = new Date(utcDate);

    // 将时间转换为中国时区（CST，UTC+8）
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // 24小时制
    };

    return date.toLocaleString('zh-CN', options).replace('/', '-').replace('/', '-');
};


// 页面挂载时执行
onMounted(() => {
    handleSearch()
    queryMerchantList()
});

</script>

<style scoped>
/* src/assets/styles/global.css */
/* 滚动条整体部分 */
::v-deep .el-scrollbar__bar {
    opacity: 1;
    /* 使滚动条不透明 */
}

/* 横向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
    border-radius: 1px;
    /* 滑块的圆角 */
    background-color: #818181;
    /* 滑块的背景颜色 */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    /* 滑块的阴影 */
}

/* 纵向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
    border-radius: 1px;
    background-color: #818181;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

/* 滚动条的轨道部分 */
::v-deep .el-scrollbar__wrap {
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* 轨道的背景颜色 */
    }
}

/* 修改滑块宽度 */
::v-deep .el-scrollbar__bar.is-horizontal {
    height: 12px;
}

::v-deep .el-scrollbar__bar.is-vertical {
    width: 12px;
}
</style>